import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'striphtml',
  standalone: true
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string | undefined): any {
    if (value) {
      return value.replace(/<[\s\S]*?>/g, ''); // replace tags
    }
  }
}
